import React, { Component, useState, useEffect } from 'react'
import Head from 'react-helmet'
import axios from 'axios'
import {
  createStyles,
  withStyles,
  Typography,
} from '@material-ui/core'

import Layout from '../components/Layout'

import iosLoader from '../images/ios-loader.gif'

import getApp from '../images/get-app.png'
import googleButton from '../images/google-button.png'
import appleButton from '../images/apple-button.png'
import availableOnApple from '../images/availableOnApple.png'

const avocadoFinanceUrl = process.env.GATSBY_AF_URL
const countApiName = process.env.GATSBY_COUNT_API_KEY
const waitlistName = process.env.GATSBY_WAITLIST_KEY
const emailValidateApiKey = process.env.GATSBY_WHOIS_API_KEY
const linkedInUrl = process.env.LINKED_IN_URL

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'
const isEmailAddress = (x) =>
  /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i.test(
    x,
  )

const apiUrl = `https://avocado-finance.app.waitlisted.co/api/v2/reservations`

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 125,
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 125,
      },
    },
    image: {
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        marginBottom: 60,
      },
      [theme.breakpoints.up('md')]: {
        flex: 1,
        maxWidth: 360,
        marginRight: 40,
      },
      [theme.breakpoints.up('lg')]: {
        flex: 1,
        maxWidth: 540,
        marginRight: 60,
      },
    },
    downloadContainer: {
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
      [theme.breakpoints.up('md')]: {
        flex: 1,
      },
      [theme.breakpoints.up('lg')]: {
        flex: 1,
      },
    },
    inputContainer: {
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '25px',
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '25px',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '25px',
      },
    },
    fieldInput: {
      [theme.breakpoints.down('sm')]: {
        fontFamily: 'Montserrat',
        boxShadow: 'none',
        appearance: 'none',
        fontSize: 16,
        color: '#000000',
        width: '95%',
        // fontSize: 12,
        fontWeight: 400,
        borderRadius: '50px',
        borderColor: '#E1E1E1',
        borderWidth: 1,
        padding: '10px 10px',
        border: '0.0187rem solid rgba(255,0,128,1)',
        marginBottom: 10,
        '&::placeholder': {
          color: '#C8C2C2'
        }
      },
      [theme.breakpoints.up('md')]: {
        fontFamily: 'Montserrat',
        boxShadow: 'none',
        appearance: 'none',
        width: 200,
        color: '#000000',
        fontSize: 14,
        fontWeight: 400,
        borderRadius: '50px',
        borderColor: '#E1E1E1',
        borderWidth: 1,
        padding: '15px 25px',
        border: '0.0187rem solid rgba(255,0,128,1)',
        marginRight: 5,
        '&::placeholder': {
          color: '#C8C2C2'
        }
      },
      [theme.breakpoints.up('lg')]: {
        fontFamily: 'Montserrat',
        boxShadow: 'none',
        appearance: 'none',
        width: 285,
        color: '#000000',
        fontSize: 14,
        fontWeight: 400,
        borderRadius: '50px',
        borderColor: '#E1E1E1',
        borderWidth: 1,
        padding: '15px 25px',
        border: '0.0187rem solid rgba(255,0,128,1)',
        marginRight: 5,
        '&::placeholder': {
          color: '#C8C2C2'
        }
      },
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FF0080',
        color: '#ffffff',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: 16,
        width: '100%',
        borderRadius: '100px',
        borderWidth: 0,
        padding: '10px 10px',
        cursor: 'pointer',
      },
      [theme.breakpoints.up('md')]: {
        backgroundColor: '#FF0080',
        color: '#ffffff',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: 16,
        width: 180,
        borderRadius: '100px',
        borderWidth: 0,
        cursor: 'pointer',
        padding: '8px 0',
      },
      [theme.breakpoints.up('lg')]: {
        backgroundColor: '#FF0080',
        color: '#ffffff',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: 16,
        width: 220,
        borderRadius: '100px',
        borderWidth: 0,
        cursor: 'pointer',
        padding: '8px 0',
      },
    },
    title: {
      color: '#F0EDEF',
      fontWeight: 800,
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px',
        marginBottom: 20,
      },
      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
        fontSize: '30px',
      },
      [theme.breakpoints.up('lg')]: {
        textAlign: 'left',
        fontSize: '44px',
      },
    },
    playButtonContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '40px',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    googleButton: {
      [theme.breakpoints.down('sm')]: {
        width: '158px',
      },
      [theme.breakpoints.up('md')]: {
        width: '158px',
        height: '47px',
        cursor: 'pointer',
      },
      [theme.breakpoints.up('lg')]: {
        width: '158px',
        height: '47px',
        cursor: 'pointer',
      },
    },
    appleButton: {
      [theme.breakpoints.down('sm')]: {
        width: '142px',
      },
      [theme.breakpoints.up('md')]: {
        width: '142px',
        height: '47px',
        marginRight: 10,
        cursor: 'pointer',
      },
      [theme.breakpoints.up('lg')]: {
        width: '142px',
        height: '47px',
        marginRight: 10,
        cursor: 'pointer',
      },
    },
    errorMessage: {
      [theme.breakpoints.down('xs')]: {
        margin: '0',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        fontSize: 12,
        color: '#ffffff',
        position: 'absolute',
        top: '100px',
      },

      [theme.breakpoints.up('md')]: {
        margin: '0',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        fontSize: 12,
        color: '#ffffff',
        position: 'absolute',
        left: '25px',
        top: '55px',
      },
    },
    loader: {
      width: '25px'
    },
    confirmedText: {
      color: '#F0EDEF',
      fontFamily: 'Montserrat',
      fontWeight: '800',
      fontSize: 14,
      textAlign: 'left',
    },
  })

const GetApp = ({ classes }) => {
  const [value, setValue] = useState('')
  const [rawValue, setRawValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
    else return null;
  }

  const sendText = () => {
    setErrorMessage('')
    setIsLoading(true)
    setIsConfirmed(false)

    console.info('************')
    console.info(rawValue)
    console.info(getCookie(rawValue))
    console.info('************')

    if (getCookie(rawValue)) {
      setErrorMessage('A text has already been sent to this number.')
      setIsLoading(false)
      return
    }

    if (rawValue.length < 10) {
      setErrorMessage('Invalid phone number.')
      setIsLoading(false)
      return
    }

    const url = 'https://af-twilio-sms.herokuapp.com/api/messages'
    const message = 'Welcome to Avocado Finance! Here is a link to the app: apple.co/3HXeNW0'
    const to = `${value}`

    const headers = {
      // 'Content-Type': 'application/json'
    }

    axios.post(url, {
      body: message,
      to: `+1${to}`
    }, { headers: headers })
      .then(res => {
        setCookie()
        console.info('Twilio success')
        console.info(res)
        setIsLoading(false)
        setIsConfirmed(true)
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false)
      })
  }



  const handleInput = (e) => {
    const phoneValue = e.target.value
    const phoneRaw = phoneValue.replace(/\D/g, '')
    const formattedPhoneNumber = formatPhoneNumber(phoneValue)
    setRawValue(phoneRaw)
    setValue(formattedPhoneNumber)
  }

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '')

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`
  }

  const onSubmit = (e) => {
    e.preventDefault()
    sendText()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit(e)
    }
  }

  const setCookie = () => {
    var now = new Date()
    now.setTime(now.getTime() + 1 * 3600 * 1000)
    document.cookie = `cookieName=${rawValue}; expires=" + now.toUTCString() + "; path=/"`
  }

  return (
    <Layout>
      <Head>
        <title>Get the App</title>
      </Head>
      <div className={classes.container}>
        <img className={classes.image} src={getApp} />
        <div classname={classes.downloadContainer}>
          <Typography variant="h1" className={classes.title} align="center">
            Download the App
          </Typography>
          {
            isConfirmed
              ? (
                <p className={classes.confirmedText}>Great, we texted you a link to the app!</p>
              )
              : (

                <div className={classes.inputContainer}>
                  <input
                    type="text"
                    value={value}
                    onChange={handleInput}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter phone number"
                    className={classes.fieldInput}
                  />
                  <button
                    type="button"
                    className={classes.button}
                    onClick={onSubmit}
                  >
                    {
                      isLoading
                        ? <img className={classes.loader} src={iosLoader} />
                        : isConfirmed ? 'Done' : 'Text me the app'
                    }
                  </button>
                  {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
                </div>
              )
          }
          <div className={classes.playButtonContainer}>
            <img className={classes.appleButton} src={availableOnApple} />
            {/* <img className={classes.googleButton} src={googleButton} /> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default withStyles(styles)(GetApp)
